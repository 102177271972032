<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Búsqueda de ordenes</p>
    </header>
    <section class="modal-card-body">
      <b-table :data="data"
               ref="orders_table"
               @keyup.enter.native="onKeyPressEnter"
               @keyup.37.native="onKeyPressLeft"
               @keyup.39.native="onKeyPressRight"
               :selected.sync="selected"
               @dblclick="onSelectOrder"
               focusable>
        <b-table-column label="Orden" width="40" numeric v-slot="props">
          {{ props.row.no }}
        </b-table-column>
        <b-table-column v-if="showFolio" label="Folio" width="40" numeric v-slot="props">
          {{ props.row.folio }}
        </b-table-column>
        <b-table-column label="Fecha" v-slot="props">
          {{ props.row.date | date('LL') }}
        </b-table-column>
        <b-table-column label="Hora" v-slot="props">
          {{ props.row.time | date('H:m A') }}
        </b-table-column>
        <b-table-column label="Paciente" v-slot="props">
          {{ props.row.patient_code }}
        </b-table-column>
        <b-table-column label="Sucursal" v-slot="props">
          {{ getNameBranchOffice(props.row.branch_office) }}
        </b-table-column>
        <b-table-column label="Empresa" v-slot="props">
          <span v-if="props.row.has_company">
            {{ props.row.company.name }}
          </span>
        </b-table-column>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button"
              @click="$parent.close()">
        Cerrar
      </button>
      <button class="button is-primary" type="button" @click="onSelectOrder" :disabled="!selected">
        Seleccionar
      </button>
    </footer>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  name: "ModalSelectOrder",
  props: {
    data: Array,
    showFolio: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: null,
      currentPage: 1,
      perPage: 10
    }
  },
  computed: {
    ...mapState({
      branchOffices: state => state.main.branchOffices
    }),
  },
  mounted() {
    if (this.data.length) {
      this.selected = this.data[0];
      setTimeout(() => {
        this.$refs.orders_table.focus();
      }, 100);
    }
  },
  methods: {
    getNameBranchOffice(id) {
      return this.branchOffices.find(el => el.id == id).name
    },
    onSelectOrder() {
      this.$emit('selectOrder', this.selected);
      this.$parent.close();
    },
    onKeyPressEnter() {
      this.getNameBranchOffice(this.selected);
    },
    onKeyPressLeft() {
      if (this.data.length > this.perPage) {
        if (this.currentPage > 1) {
          this.currentPage -= 1;
        }
      }
    },
    onKeyPressRight() {
      if (this.data.length > this.perPage) {
        let pages = Math.ceil(this.data.length / this.perPage);
        if (this.currentPage < pages) {
          this.currentPage += 1;
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
